<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="xl"
      :show.sync="modalActive"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow class="mb-4">
                <CCol sm="4">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.commodityType')"
                    size="sm"
                    v-model.trim="commodityInfo.type"
                    disabled
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.state')"
                    size="sm"
                    v-model.trim="commodityInfo.state"
                    disabled
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.commodity')"
                    size="sm"
                    v-model.trim="commodityInfo.name"
                    disabled
                  />
                </CCol>

                <CCol sm="4">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.select')"
                    addLabelClasses="required text-right"
                    :label="'BL'"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    :options="blOptions"
                    :disabled="isEmpty(billOfLoadings)"
                    :value.sync="$v.orderInfo.BillOfLadingYardId.$model"
                    :invalid-feedback="
                      errorMessage($v.orderInfo.BillOfLadingYardId)
                    "
                    :is-valid="hasError($v.orderInfo.BillOfLadingYardId)"
                  >
                  </CSelect>
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="required text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.client')"
                    size="sm"
                    v-model.trim="blClientName"
                    disabled
                  />
                </CCol>
                <CCol sm="4">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.select')"
                    addLabelClasses="required text-right"
                    :label="$t('label.yard')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    :options="yardOptions"
                    :disabled="isEmpty(yards)"
                    :value.sync="$v.orderInfo.YardId.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.YardId)"
                    :is-valid="hasError($v.orderInfo.YardId)"
                  >
                  </CSelect>
                </CCol>

                <CCol sm="4">
                  <CInput
                    addLabelClasses="text-right"
                    :placeholder="$t('label.nroGuide')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.nroGuide')"
                    maxlength="20"
                    size="sm"
                    v-model.trim="$v.orderInfo.GuideNumber.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.GuideNumber)"
                    :is-valid="hasError($v.orderInfo.GuideNumber)"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="required text-right"
                    :placeholder="$t('label.nroControl')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.nroControl')"
                    maxlength="20"
                    size="sm"
                    v-model.trim="$v.orderInfo.ControlNumber.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.ControlNumber)"
                    :is-valid="hasError($v.orderInfo.ControlNumber)"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    size="sm"
                    ref="weightInput"
                    :label="$t('label.netWeight') + '(KG)'"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    placeholder="0"
                    addLabelClasses="text-right"
                    v-model.trim="netWeight"
                    required
                    disabled
                  />
                </CCol>

                <CCol sm="4">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.select')"
                    addLabelClasses="text-right"
                    :label="$t('label.carrierCompany')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    :options="transportOptions"
                    :disabled="isEmpty(transports)"
                    :value.sync="$v.orderInfo.ClientTpId.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.ClientTpId)"
                    :is-valid="hasError($v.orderInfo.ClientTpId)"
                  >
                    <template #append>
                      <CButton
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0"
                        v-c-tooltip="{
                          content: $t('label.nuevo'),
                          placement: 'top-end',
                        }"
                        @click="handleCollapse"
                      >
                        <CIcon name="cil-playlist-add" />
                      </CButton>
                    </template>
                  </CSelect>
                  <loading v-show="loadingTransport" element="select" />
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.carrierRif')"
                    size="sm"
                    v-model.trim="transportInfo.rif"
                    disabled
                  />
                </CCol>
                <CCol sm="4">
                  <div role="group" class="form-group form-row">
                    <label
                      class="
                        text-right
                        required
                        col-form-label col-sm-12 col-lg-5 col-form-label-sm
                      "
                    >
                      {{ $t("label.receptionDate") }}
                    </label>
                    <div class="col-sm-12 col-lg-7 input-group-sm">
                      <vue-datepicker
                        :lang="$i18n.locale"
                        v-model="$v.orderInfo.TransactionDate.$model"
                        type="datetime"
                        format="DD/MM/YYYY HH:mm"
                        value-type="format"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        :show-second="false"
                        :clearable="false"
                        popup-class="vuedatapickerespecial"
                        :editable="false"
                        :append-to-body="false"
                        :disabled-date="dateValidationByYearQty"
                        class="col-sm-10 col-lg-12 col-xl-12 px-0 vue-datepicker-drive:focus"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            :is-valid="hasError($v.orderInfo.TransactionDate)"
                            :invalid-feedback="
                              errorMessage($v.orderInfo.TransactionDate)
                            "
                            v-model.trim="$v.orderInfo.TransactionDate.$model"
                            @keypress="rightDate($event)"
                            class="mb-0"
                            size="sm"
                            placeholder="DD/MM/YYYY HH:mm"
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>

                <CCol sm="4">
                  <CInput
                    addLabelClasses="required text-right"
                    :placeholder="$t('label.driverId')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    :label="$t('label.driverId')"
                    maxlength="10"
                    size="sm"
                    v-model.trim="$v.orderInfo.DriverCi.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.DriverCi)"
                    :is-valid="hasError($v.orderInfo.DriverCi)"
                  >
                    <template #prepend>
                      <CSelect
                        :value.sync="ciDriverLetter"
                        :options="ciTypeOptions"
                        class="mr-1 mb-0"
                        size="sm"
                      />
                    </template>
                    <template #append>
                      <CButton
                        shape="square"
                        color="watch"
                        size="sm"
                        class="pt-0"
                        v-c-tooltip="{
                          content: $t('label.search') + ' ' + $t('label.driver'),
                          placement: 'top-end',
                        }"
                        @click="searchDriver"
                      >
                        <div v-if="!searchingDriver">
                          <CIcon name="cil-search" />
                        </div>
                        <div v-if="searchingDriver">
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </CButton>
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="required text-right"
                    :placeholder="$t('label.driver')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.driver')"
                    maxlength="20"
                    size="sm"
                    v-model.trim="$v.orderInfo.DriverName.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.DriverName)"
                    :is-valid="hasError($v.orderInfo.DriverName)"
                    :disabled="!isDriverSearched"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    addLabelClasses="required text-right"
                    :placeholder="$t('label.carPlate')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.carPlate')"
                    size="sm"
                    v-model.trim="$v.orderInfo.LicensePlate.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.LicensePlate)"
                    :is-valid="hasError($v.orderInfo.LicensePlate)"
                  />
                </CCol>

                <CCol sm="8">
                  <CTextarea
                    size="sm"
                    addWrapperClasses="input-textarea-order-metalscrap"
                    addLabelClasses="text-right label-textarea-order-metalscrap"
                    v-uppercase
                    :label="$t('label.inventoryContains')"
                    :placeholder="$t('label.inventoryContains')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-3',
                      input: 'col-sm-12 col-lg-9',
                    }"
                    rows="2"
                    maxlength="150"
                    v-model.trim="$v.orderInfo.Observation.$model"
                    :invalid-feedback="errorMessage($v.orderInfo.Observation)"
                    :is-valid="hasError($v.orderInfo.Observation)"
                  />
                </CCol>
                <CCol sm="4" v-if="edit">
                  <div>
                    <CSelect
                      :value.sync="orderInfo.Status"
                      :is-valid="statusSelectColor()"
                      :horizontal="{
                        label: 'col-sm-12 col-lg-5',
                        input: 'col-sm-12 col-lg-7',
                      }"
                      size="sm"
                      :label="$t('label.status')"
                      :options="statusOptions"
                      addLabelClasses="text-right"
                    />
                  </div>
                </CCol>
              </CRow>

              <!-- Collapse Clientes -->
              <CRow>
                <CCol sm="12">
                  <CCollapse :show="collapse">
                    <CRow>
                      <CCol sm="12" v-if="collapse">
                        <AddCliente
                          metalScrap="ClientCarrier-insert"
                          @child-refresh="handleCollapse"
                          @update-list="handleRefresh"
                        />
                      </CCol>
                    </CRow>
                  </CCollapse>
                </CCol>
              </CRow>

              <!-- Movimientos -->
              <CRow>
                <CCol sm="6">
                  <MovementInputs
                    :movementsType="movementsTypeOptions"
                    :scales="scalesOptions"
                    :item="movementToEdit"
                    :edit="editMovement"
                    :current="formatedMovements"
                    :modalOpen="modalActive"
                    @clear="clearMovement"
                    @submit="submitMovement"
                    @update="updateMovement"
                  />
                </CCol>
                <CCol sm="6">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="formatedMovements"
                    :fields="fieldsMovements"
                    :loading="loading"
                    column-filter
                    :noItemsView="tableText.noItemsViewText"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items-per-page="tableText.itemsPerPage"
                    hover
                    small
                    sorter
                    pagination
                  >
                    <template #loading>
                      <loading />
                    </template>
                    <template #Detalle="{ item }">
                      <td class="text-center align-middle">
                        <CButtonGroup>
                          <CButton
                            shape="square"
                            color="edit"
                            size="sm"
                            v-c-tooltip="
                              $t('label.edit') + ' ' + $t('label.movement')
                            "
                            @click.prevent="editMovementInfo(item)"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                        </CButtonGroup>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="toggle(false)"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import EntryOrderValidation from "@/_validations/metalscrap/EntryOrderFormValidations";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import MetalScrap from "@/_mixins/metalscrap";
import mixinServicio from "@/_mixins/servicio";
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from "@/_validations/validacionEspeciales";
import { dateValidationByYearQty } from "@/_validations/funciones";

import AddCliente from "@/pages/cliente/add-cliente";
import MovementInputs from "./movement-inputs";

//DATA
function data() {
  return {
    modalActive: false,
    orderInfo: {
      OrderId: "",
      BillOfLadingYardId: "",
      YardId: "",
      ClientTpId: "",
      DriverId: "",
      DriverCi: "",
      DriverName: "",
      VehicleId: "",
      LicensePlate: "",
      GuideNumber: "",
      ControlNumber: "",
      TransactionDate: "",
      Observation: "",
      Status: 1,
      OrderDetailJson: [],
    },
    commodityInfo: {
      state: "",
      type: "",
      name: "",
    },
    isDriverSearched: false,
    ciDriverLetter: "V",
    billOfLoadings: [],
    blClientName: "",
    scales: [],
    movementsType: [],
    modalTitle: "",
    transportInfo: {
      rif: "",
    },
    oldStatus: 1,
    yards: [],
    loading: false,
    transports: [],
    loadingTransport: false,
    TpClientId: "2f8322be-ca77-ec11-a876-000d3a0dedc7",
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    openDate: false,
    collapse: false,
    movementToEdit: null,
    editMovement: false,
    searchingDriver: false,
    searchingPlate: false,
  };
}

// METHODS
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else this.loadData();

  this.modalActive = newVal;
}
function resetForm() {
  let arrKeys = Object.keys(this.orderInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.orderInfo[arrKeys[i]] = 1;
        break;
      case "OrderDetailJson":
        this.orderInfo[arrKeys[i]] = [];
        break;
      default:
        this.orderInfo[arrKeys[i]] = "";
    }
  }
  this.oldStatus = 1;
  this.transportInfo.rif = "";
  this.commodityInfo.type = "";
  this.commodityInfo.state = "";
  this.commodityInfo.name = "";
  this.yards = [];
  this.transports = [];
  this.movementsType = [];
  this.billOfLoadings = [];
  this.scales = [];
  this.modalTitle = "";
  this.blClientName = "";
  this.ciDriverLetter = "V";

  this.collapse = false;
  this.isDriverSearched = false;
  this.movementToEdit = null;
  this.editMovement = false;

  this.loadingTransport = false;
  this.$v.$reset();
}
function setForm(order) {
  let arrKeys = Object.keys(this.orderInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "OrderDetailJson":
        this.orderInfo[arrKeys[i]] = Array.isArray(order[arrKeys[i]])
          ? order[arrKeys[i]].map((item) =>
              Object.assign(
                {},
                item,
                {
                  TransactionDate: this.formatDateTime(item.TransactionDate),
                }
              )
            )
          : [];
        break;
      case "DriverCi":
        let arrId = order[arrKeys[i]].split(/[VEP]/);
        this.ciDriverLetter = arrId.length == 2 ? arrId[0] : 'V';
        this.orderInfo[arrKeys[i]] = arrId.length == 2 ? arrId[1] : arrId[0];
        break;
      case "TransactionDate":
        this.orderInfo[arrKeys[i]] = order[arrKeys[i]]
          ? this.formatDateTime(order[arrKeys[i]])
          : "";
        break;
      case "Status":
        this.orderInfo[arrKeys[i]] = order[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = order[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.orderInfo[arrKeys[i]] = order[arrKeys[i]] ? order[arrKeys[i]] : "";
    }
  }
  this.isDriverSearched = true;
  this.modalTitle = `${order.NroBl} ${order.YardName}`;
  this.transportInfo.rif = order.ClientRif ? order.ClientRif : "";
  this.$v.$touch();
}
function loadData() {
  this.loading = true;

  let peticiones = !this.edit
    ? [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "BillOfLadingReception-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "BulkCargoConfig-list"),
        this.$http.ejecutar("GET", "TruckWeighingScale-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "EntryMovementOrder-list"),
      ]
    : [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "BillOfLadingReception-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "BulkCargoConfig-list"),
        this.$http.ejecutar("GET", "TruckWeighingScale-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "EntryMovementOrder-list"),
        this.$http.ejecutar("GET", "EntryOrder-by-id", {
          OrderId: this.entryOrderId,
        }),
      ];

  Promise.all(peticiones)
    .then((responses) => {
      this.transports = responses[0].data.data;
      this.billOfLoadings = responses[1].data.data;
      this.commodityInfo = {
        ...this.commodityInfo,
        name: responses[2].data.data[0].CommodityJson[0].CommodityName,
        type: responses[2].data.data[0].TpCargoJson[0].GpoCargoName,
        state: responses[2].data.data[0].TpCargoJson[0].TpCargoName,
      };
      this.scales = responses[3].data.data;
      this.movementsType = responses[4].data.data;

      if (this.edit) {
        this.setForm(responses[5].data.data[0]);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
      console.log(err);
    })
    .then(() => {
      this.loading = false;
    });
}
function formatNumericValue(val) {
  return parseFloat(val.replace(".", "").replace(",", ".")).toFixed(2);
}
function formatedData() {
  if (!this.edit) {
    let newOrder = {
      ...this.orderInfo,
      DriverCi: `${this.ciDriverLetter}${this.orderInfo.DriverCi}`,
      TransactionDate: this.orderInfo.TransactionDate
        ? this.formatDateTime(this.orderInfo.TransactionDate, true)
        : "",
      OrderDetailJson: this.orderInfo.OrderDetailJson.map((item) =>
        Object.assign(
          {},
          {
            MovementOrderId: item.MovementOrderId,
            TransactionDate: this.formatDateTime(item.TransactionDate, true),
            Weight: item.Weight,
            TruckWeghingScaleId: item.TruckWeghingScaleId,
          }
        )
      ),
    };

    delete newOrder.OrderId;
    delete newOrder.Status;

    return newOrder;
  } else {
    return {
      ...this.orderInfo,
      DriverCi: `${this.ciDriverLetter}${this.orderInfo.DriverCi}`,
      TransactionDate: this.orderInfo.TransactionDate
        ? this.formatDateTime(this.orderInfo.TransactionDate, true)
        : "",
      OrderDetailJson: this.orderInfo.OrderDetailJson.map((item) =>
        Object.assign(
          {},
          {
            OrderDetailId: item.OrderDetailId,
            MovementOrderId: item.MovementOrderId,
            TransactionDate: this.formatDateTime(item.TransactionDate, true),
            Weight: item.Weight,
            TruckWeghingScaleId: item.TruckWeghingScaleId,
            Status:
              typeof item.Status != "number"
                ? item.Status == "ACTIVO"
                  ? 1
                  : 0
                : item.Status,
          }
        )
      ),
    };
  }
}
function submit() {
  try {
    this.$v.orderInfo.$touch();
    if (this.$v.orderInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit ? "EntryOrder-update" : "EntryOrder-insert";
    let EntryOrderJson = this.formatedData();
    let metodo = this.edit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, EntryOrderJson, {
        root: "EntryOrderJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function isEmpty(arr) {
  return arr.length == 0;
}
function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}
function formatCharDate(e) {
  var regex = new RegExp("^[0-9-/]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function confirmation() {
  try {
    this.$v.orderInfo.$touch();
    if (this.$v.orderInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.orderInfo.Status,
      this.submit,
      `${this.modalTitle}`
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function getTransports() {
  this.loadingTransport = true;
  this.$http
    .get("Client-list-by-activity", {
      TpClientId: this.TpClientId,
      Filter: "ACTIVO",
    })
    .then((response) => {
      this.transports = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingTransport = false;
    });
}

function handleCollapse() {
  if (this.collapse) {
    this.getTransports();
    this.collapse = false;
  } else {
    this.collapse = true;
  }
}
function handleRefresh() {
  this.getTransports();
}

function editMovementInfo(item) {
  this.movementToEdit = { ...item };
  this.editMovement = true;
}
function clearMovement() {
  this.editMovement = false;
  this.movementToEdit = null;
}
function submitMovement(movement) {
  this.orderInfo.OrderDetailJson.push(movement);
}
function updateMovement(response) {
  let info = { ...response };

  let index = this.orderInfo.OrderDetailJson.findIndex(
    (movement) => movement.OrderDetailId == info.OrderDetailId
  );

  if (index == -1) {
    let flag = this.orderInfo.OrderDetailJson.findIndex(
      (movement) => movement.MovementOrderId == info.MovementOrderId
    );

    if (flag == -1) return;

    let toReplace = { ...this.orderInfo.OrderDetailJson[flag], ...info };
    this.orderInfo.OrderDetailJson.splice(flag, 1, toReplace);
  } else {
    let toReplace = { ...this.orderInfo.OrderDetailJson[index], ...info };
    this.orderInfo.OrderDetailJson.splice(index, 1, toReplace);
  }

  this.editMovement = false;
  this.movementToEdit = null;
}

function searchDriver() {
  this.searchingDriver = true;
  this.$http
    .get("Driver-by-Ci", {
      DriverCi: `${this.ciDriverLetter}${this.orderInfo.DriverCi}`,
    })
    .then((response) => {
      if(response.data.data.length != 0) {
        this.orderInfo.DriverId = response.data.data[0].DriverId;
        this.orderInfo.DriverName  = response.data.data[0].DriverName;
      }
      this.isDriverSearched = true;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.searchingDriver = false;
    });
}
function searchPlate() {
  this.searchingPlate = true;
  this.$http
    .get("Vehicle-by-LicensePlate", {
      LicensePlate: this.orderInfo.LicensePlate,
    })
    .then((response) => {
      if(response.data.data.length != 0)
        this.orderInfo.VehicleId = response.data.data[0].VehicleId;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.searchingPlate = false;
    });
}

//COMPUTED
function tituloModal() {
  if (!this.edit) {
    return this.$t("label.newReception");
  } else {
    return `${this.$t("label.editReception")}: ${
      this.modalTitle
    }`;
  }
}
function yardOptions() {
  return this.yards.map((yard) =>
    Object.assign({}, yard, {
      label: yard.YardName,
      value: yard.YardId,
    })
  );
}
function transportOptions() {
  return this.transports.map((transport) =>
    Object.assign({}, transport, {
      label: transport.ClientName,
      value: transport.ClientTpId,
    })
  );
}
function blOptions() {
  return this.billOfLoadings.map((billOfLoading) =>
    Object.assign({}, billOfLoading, {
      label: billOfLoading.NroBl,
      value: billOfLoading.BillOfLadingYardId,
    })
  );
}
function statusSelectColor() {
  return this.orderInfo.Status === 1;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function fieldsMovements() {
  return [
    { key: "Nro", label: "#", _style: "width:1%;", _classes: "text-center" },
    { key: "MovementType", label: this.$t("label.typeMovement") },
    { key: "Weight", label: this.$t("label.weight") + " (KG)" },
    { key: "User", label: this.$t("label.user") },
    { key: "Date", label: this.$t("label.date"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    MovementType: "align-middle",
    Weight: "align-middle",
    User: "align-middle",
    Date: "align-middle",
  };
}
function formatedMovements() {
  let index = 1;
  return this.orderInfo.OrderDetailJson.map((mov) => {
    let movementName = "";
    let foundMov = this.movementsType.find(
      (item) => item.MovementOrderId == mov.MovementOrderId
    );
    if (foundMov)
      movementName =
        this.$i18n.locale == "es"
          ? foundMov.MovementOrderNameEs
          : foundMov.MovementOrderNameEn;

    return Object.assign({}, mov, {
      Nro: index++,
      MovementType:
        mov.MovementOrderNameEs || mov.MovementOrderNameEn
          ? this.$i18n.locale == "es"
            ? mov.MovementOrderNameEs
            : mov.MovementOrderNameEn
          : movementName,
      Weight: formatMilDecimal(mov.Weight),
      // TransactionDate: this.formatDateTime(mov.TransactionDate),
      User: mov.TransaLogin ? mov.TransaLogin : this.user.Login,
      Date: mov.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(mov.TransaRegDate)
        : DateFormater.formatOnlyDateWithSlash(new Date()),
      Status:
        typeof mov.Status == "number"
          ? mov.Status == 1
            ? "ACTIVO"
            : "INACTIVO"
          : mov.Status,
      // _classes:
      //   typeof mov.Status == "number"
      //     ? mov.Status == 1
      //       ? ""
      //       : "table-danger"
      //     : mov.Status == "ACTIVO"
      //     ? ""
      //     : "table-danger",
      _cellClasses: this.cellTableClasses,
    });
  });
}
function currentTransport() {
  return this.orderInfo.ClientTpId;
}
function netWeight() {
  let totalFull = 0, totalEmpty = 0;
  for (let index = 0; index < this.orderInfo.OrderDetailJson.length; index++) {
    if (
      this.orderInfo.OrderDetailJson[index].Status == "ACTIVO" ||
      this.orderInfo.OrderDetailJson[index].Status == 1
    )
      if(this.orderInfo.OrderDetailJson[index].MovementOrderId == '0CD75090-B364-4DB9-A703-94184718E84B')
        totalFull += this.orderInfo.OrderDetailJson[index].Weight;
      
      if(this.orderInfo.OrderDetailJson[index].MovementOrderId == '4ACAD67A-C8DA-4CB3-896A-C889535812CC')
        totalEmpty += this.orderInfo.OrderDetailJson[index].Weight;
  }
  let netWeight = totalFull - totalEmpty;
  if(netWeight < 0)
    return 0;
  else
    return formatMilDecimal(totalFull - totalEmpty);
}

function ciTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
  ];
}
function blClient() {
  return this.orderInfo.BillOfLadingYardId;
}
function movementsTypeOptions() {
  return this.movementsType.map((mov) =>
    Object.assign({}, mov, {
      label: this.$i18n.locale == "es" ? mov.MovementOrderNameEs : mov.MovementOrderNameEn,
      value: mov.MovementOrderId,
    })
  );
}
function scalesOptions() {
  return this.scales.map((scale) =>
    Object.assign({}, scale, {
      label: scale.TruckWeghingScaleName,
      value: scale.TruckWeghingScaleId,
    })
  );
}

function currentDriverCi() {
  return this.orderInfo.DriverCi;
}
function currentPlate() {
  return this.orderInfo.LicensePlate;
}

export default {
  name: "entry-order-modal",
  mixins: [ModalMixin, General, mixinServicio, MetalScrap],
  data,
  components: {
    AddCliente,
    MovementInputs,
  },
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    entryOrderId: {
      type: String,
      default: "",
    },
  },
  validations: EntryOrderValidation,
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    currentTransport: function (val) {
      if (val) {
        let found = this.transports.find((item) => item.ClientTpId == val);
        this.transportInfo.rif = found ? found.ClientRif : "";
      } else {
        this.transportInfo.rif = "";
      }
    },
    blClient: function (val) {
      if (val) {
        let found = this.billOfLoadings.find(
          (item) => item.BillOfLadingYardId == val
        );
        if (found) {
          this.blClientName = found.ClientName;
          this.yards = found.YardJson ? [...found.YardJson] : [];
        }
      } else {
        this.blClientName = "";
        this.yards = [];
        this.orderInfo.YardId = "";
      }
    },
    currentDriverCi: function(newVal, oldVal) {
      if(newVal != oldVal){
        this.orderInfo.DriverId = '';
      }
    },
    currentPlate: function(newVal, oldVal) {
      if(newVal != oldVal){
        this.orderInfo.VehicleId = '';
      }
    }
  },
  methods: {
    loadData,
    toggle,
    resetForm,
    setForm,
    isEmpty,
    formatedData,
    submit,
    functionDate,
    formatCharDate,
    confirmation,
    statusSelectColor,
    formatNumericValue,
    getTransports,
    handleCollapse,
    handleRefresh,
    clearMovement,
    submitMovement,
    updateMovement,
    editMovementInfo,
    searchDriver,
    searchPlate,
    dateValidationByYearQty,
  },
  computed: {
    tituloModal,
    yardOptions,
    transportOptions,
    blOptions,
    getBranchId,
    fieldsMovements,
    cellTableClasses,
    formatedMovements,
    currentTransport,
    netWeight,
    ciTypeOptions,
    blClient,
    movementsTypeOptions,
    scalesOptions,
    currentDriverCi,
    currentPlate,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style lang="scss" scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}

.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}
</style>